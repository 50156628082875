import React from 'react';
import { Formiz, useForm } from '@formiz/core';
import { isEmail, isPattern } from '@formiz/validations';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Grid } from 'semantic-ui-react';
import { actions } from 'store';

import ImageCustom from 'components/core/ImageCustom/ImageCustom';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Spacer from 'components/core/Spacer/Spacer';
import FormizSimpleInput from 'components/FormizField/FormizSimpleInput/FormizSimpleInput';
import { passwordRegex } from 'interfaces/formSchema';
import authPaths from 'routes/auth/paths';
import images from 'assets/images';
import ImageLogin from 'components/core/ImageLogin/ImageLogin';

const Index = ({ dispatch, history, intl }) => {
  const signUpForm = useForm();

  const save = (values) => {
    dispatch(
      actions.user.createUser({
        data: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
        },
      })
    );

    history.push(authPaths.login);
  };

  return (
    <Wrapper
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <ImageLogin
        alt='Logo ASD'
        margin='50px'
        height='190px'
        maxHeight='190px'
        maxWidth='20em'
      />
      <Wrapper textAlign='center' margin='0 30px 0 30px'>
        <p>We first ask you for the minimum amount of information.</p>
        <p>
          Then you will be able to register your company in our system to
          subscribe to our different services.
        </p>
      </Wrapper>
      <Wrapper maxWidth='700px' margin='30px'>
        <Formiz connect={signUpForm} onValidSubmit={save}>
          <form onSubmit={signUpForm.submit}>
            <Grid>
              <Grid.Row columns='2'>
                <Grid.Column>
                  <FormizSimpleInput
                    type='firstName'
                    name='firstName'
                    label='First name'
                    placeholder='Michel'
                    required='First name is required'
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormizSimpleInput
                    type='lastName'
                    name='lastName'
                    label='Last name'
                    placeholder='Dupont'
                    required='Last name is required'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='1'>
                <Grid.Column>
                  <FormizSimpleInput
                    type='email'
                    name='email'
                    label='Email'
                    placeholder='test@test.com'
                    required='Email is required'
                    validations={[
                      {
                        rule: isEmail(),
                        message: 'This is not a valid email',
                      },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='2'>
                <Grid.Column>
                  <FormizSimpleInput
                    type='password'
                    name='password'
                    label='Password'
                    placeholder='Password'
                    required='Password is required'
                    validations={[
                      {
                        rule: isPattern(passwordRegex),
                        message:
                          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
                      },
                    ]}
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormizSimpleInput
                    type='password'
                    name='confirmPassword'
                    label='Confirm password'
                    placeholder='Password'
                    required='Password is required'
                    validations={[
                      {
                        rule: (value) => signUpForm.values.password === value,
                        deps: [signUpForm.values.password],
                        message: 'Passwords do not match',
                      },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Spacer height='10px' />
            <Button
              size='large'
              type='submit'
              content={intl.formatMessage({
                id: 'button.save',
              })}
            />
            <Spacer height='10px' />
            <ButtonBack history={history} />
          </form>
        </Formiz>
      </Wrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
