import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import KRGlue from '@lyracom/embedded-form-glue';
import { currentLanguage } from 'views';
import countriesEN from '../../assets/json/countriesEN.json';
import countriesFR from '../../assets/json/countriesFR.json';
import spwLogo from '../../../../assets/images/logoSPWCouleur.png';
import groupLogo from '../../../../assets/images/logoGroupCouleur.png';
import euCountries from '../../assets/json/euCountries.json';
import Loader from 'components/Loader';

import './paymentPage.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import '../../assets/fonts/Muli/Muli-Regular-normal';
import '../../assets/fonts/Muli/Muli-Bold-normal';

export interface PayementPageParams {
  companyId: number;
}

export interface PricingParams {
  representation: number;
  documentStorage: number;
  management: number;
  sipsiDeclaration: number;
  constructionCard: number;
  registeredMail1: number;
  registeredMail2: number;
  registeredMail3: number;
  informationDocument: number;
  updateDocument: number;
  assistance: number;
}

const PaymentPage: React.FC<PayementPageParams> = (companyId) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  let infoDocList: string[] = [];

  const sector: string =
    window.sessionStorage.getItem('isClickedConstruction') === 'true'
      ? 'Construction'
      : 'Other';
  const countryFrom: string = window.sessionStorage.getItem(
    'selectedOptionCountryFrom'
  );
  const countryTo: string = window.sessionStorage.getItem(
    'selectedOptionCountryTo'
  );
  const numberOfEmployees: number = Number(
    window.sessionStorage.getItem('selectedOptionNumber')
  );
  const withSipsi: boolean =
    window.sessionStorage.getItem('sipsiSelectedOption')?.toLowerCase() ===
    'true';
  const withConstructionCard: boolean =
    window.sessionStorage
      .getItem('constructionCardSelectedOption')
      ?.toLowerCase() === 'true';
  const numberOfCards: number = Number(
    window.sessionStorage.getItem('selectedNewCardOption')
  );
  const startDate: string = window.sessionStorage.getItem('startDate');
  const endDate: string = window.sessionStorage.getItem('endDate');
  const languagesTab: string[] = JSON.parse(
    window.sessionStorage.getItem('checkedLanguagesArray')!
  );
  const englishDocument: boolean = checkLanguageTab('en');
  const bulgarianDocument: boolean = checkLanguageTab('bg');
  const czechDocument: boolean = checkLanguageTab('cz');
  const germanDocument: boolean = checkLanguageTab('de');
  const italianDocument: boolean = checkLanguageTab('it');
  const spanishDocument: boolean = checkLanguageTab('es');
  const portugueseDocument: boolean = checkLanguageTab('pt');
  const polishDocument: boolean = checkLanguageTab('pl');
  const arabicDocument: boolean = checkLanguageTab('ar');
  const romanianDocument: boolean = checkLanguageTab('ro');
  const amount: number = Number(
    Number(window.sessionStorage.getItem('totalPrice')).toFixed(2)
  );
  const locationName: string = window.sessionStorage.getItem('locationName');
  const locationMainAddress: string = window.sessionStorage.getItem(
    'locationMainAddress'
  );
  const locationAdditionalAddress: string = window.sessionStorage.getItem(
    'locationAdditionalAddress'
  );
  const locationPostalCode: string =
    window.sessionStorage.getItem('locationPostalCode');
  const locationCity: string = window.sessionStorage.getItem('locationCity');
  const locationCountry: string =
    window.sessionStorage.getItem('locationCountry');
  const lodgingStartDate: string =
    window.sessionStorage.getItem('lodgingStartDate');
  const lodgingEndDate: string =
    window.sessionStorage.getItem('lodgingEndDate');
  const lodgingOption: string =
    window.sessionStorage.getItem('lodgingOptionId');
  const lodgingMainAddress: string =
    window.sessionStorage.getItem('lodgingMainAddress');
  const lodgingAdditionalAddress: string = window.sessionStorage.getItem(
    'lodgingAdditionalAddress'
  );
  const lodgingPostalCode: string =
    window.sessionStorage.getItem('lodgingPostalCode');
  const lodgingCity: string = window.sessionStorage.getItem('lodgingCity');
  const lodgingCountry: string =
    window.sessionStorage.getItem('lodgingCountry');
  let reference: string;

  let endCustomerCompanyName: string = window.sessionStorage.getItem(
    'endCustomerCompanyName'
  );

  let endCustomerMainAddress: string = window.sessionStorage.getItem(
    'endCustomerMainAddress'
  );

  let endCustomerAdditionalAddress: string = window.sessionStorage.getItem(
    'endCustomerAdditionalAddress'
  );

  let endCustomerPostalCode: string = window.sessionStorage.getItem(
    'endCustomerPostalCode'
  );

  let endCustomerCity: string =
    window.sessionStorage.getItem('endCustomerCity');

  let endCustomerCountry: string =
    window.sessionStorage.getItem('endCustomerCountry');

  let endCustomerMailAddress: string = window.sessionStorage.getItem(
    'endCustomerMailAddress'
  );

  let endCustomerVatNumber: string = window.sessionStorage.getItem(
    'endCustomerVatNumber'
  );

  let endCustomerSiren: string =
    window.sessionStorage.getItem('endCustomerSiren');

  function checkLanguageTab(countryKey: string): boolean {
    if (languagesTab?.includes(countryKey)) {
      infoDocList.push(countryKey);
      return true;
    } else {
      return false;
    }
  }

  async function createMissionReference() {
    let month = new Date().getMonth() + 1;
    let newMissionReference: string =
      'SPW' +
      companyId.companyId +
      countryTo +
      (month.toString().length < 2 ? '0' + month.toString() : month) +
      new Date().getFullYear();

    newMissionReference += 'CM';

    if (sector === 'Construction') {
      newMissionReference += 'C';
    } else {
      newMissionReference += 'O';
    }

    newMissionReference += await updateCurrentMissionValue();
    reference = newMissionReference;
    return newMissionReference;
  }

  async function createInvoiceReference() {
    let year = new Date().getFullYear() % 100;
    let newInvoiceReference: string = 'S' + year;

    let id = await updateCurrentInvoiceValue();

    while (id.toString().length < 5) {
      id = '0' + id.toString();
    }

    newInvoiceReference += id;
    setInvoiceNumber(newInvoiceReference);
    return newInvoiceReference;
  }

  async function getCurrentMissionValue() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getLastMonthlyMissionId`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: 3,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function updateCurrentMissionValue() {
    const currentMissionValue: number = await getCurrentMissionValue();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateLastMonthlyMissionId`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: 3,
            updatedValue: currentMissionValue + 1,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function updateCurrentInvoiceValue() {
    const currentInvoiceValue: number = await getCurrentInvoiceValue();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateLastAnnuallyInvoiceId`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: 2,
            updatedValue: currentInvoiceValue + 1,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function createNewMission(newReference: string): Promise<number> {
    const values = JSON.stringify({
      missionReference: newReference,
      companyId: companyId.companyId,
      missionSector: sector,
      missionCountryFrom: countryFrom,
      missionCountryTo: countryTo,
      missionType: 'customMade',
      missionNumberOfEmployees: numberOfEmployees,
      missionWithSipsi: withSipsi,
      missionWithConstructionCard: withConstructionCard,
      missionNumberOfCardToCreate: numberOfCards,
      missionStartDate: startDate,
      missionEndDate: endDate,
      missionFrenchDocument: true,
      missionEnglishDocument: englishDocument,
      missionBulgarianDocument: bulgarianDocument,
      missionCzechDocument: czechDocument,
      missionGermanDocument: germanDocument,
      missionItalianDocument: italianDocument,
      missionSpanishDocument: spanishDocument,
      missionPortugueseDocument: portugueseDocument,
      missionPolishDocument: polishDocument,
      missionArabicDocument: arabicDocument,
      missionRomanianDocument: romanianDocument,
      amount: amount,
      isPaid: true,
      locationName: locationName,
      locationMainAddress: locationMainAddress,
      locationAdditionalAddress: locationAdditionalAddress,
      locationPostalCode: locationPostalCode,
      locationCity: locationCity,
      locationCountry: locationCountry,
      lodgingStartDate: lodgingStartDate,
      lodgingEndDate: lodgingEndDate,
      lodgingType: lodgingOption,
      lodgingMainAddress: lodgingMainAddress,
      lodgingAdditionalAddress: lodgingAdditionalAddress,
      lodgingPostalCode: lodgingPostalCode,
      lodgingCity: lodgingCity,
      lodgingCountry: lodgingCountry,
      creationDate: new Date(),
      endCustomerCompanyName: endCustomerCompanyName,
      endCustomerMainAddress: endCustomerMainAddress,
      endCustomerAdditionalAddress: endCustomerAdditionalAddress,
      endCustomerPostalCode: endCustomerPostalCode,
      endCustomerCity: endCustomerCity,
      endCustomerCountry: endCustomerCountry,
      endCustomerMailAddress: endCustomerMailAddress,
      endCustomerVatNumber: endCustomerVatNumber,
      endCustomerSiren: endCustomerSiren,
    });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/newMission`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: values,
        }
      );

      const result = await response.json();

      let iref = await createInvoiceReference();
      let pricing = await getPricingPolicy();
      await createInvoiceDocumentOnS3(iref, result.data, pricing);

      setTimeout(() => {
        sessionStorage.clear();
        window.location.href = `/spw/missions/${companyId.companyId}/details/${result.data}`;
      }, 1000);

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  const [file] = useState<File | null>(null);

  function uniqid(mimeType: string) {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, '').padEnd(14, '0');
    return `${id}.${mimeType}`;
  }

  async function createInvoiceDocumentOnS3(
    invoiceNumber: string,
    missionId: number,
    pricingPolicy: PricingParams
  ) {
    setIsLoading(true);
    console.log(file);
    let generateFile = await generatePDF(pricingPolicy, invoiceNumber);
    console.log(file);
    const filePath = `invoice_mission_${missionId}/${invoiceNumber}`;
    const formData = new FormData();
    let newFile = uniqid('pdf');
    formData.append('file', generateFile, newFile);
    formData.append('filePath', filePath);

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/updateEmployee/uploadEmployeeDocuments`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(await createInvoice(invoiceNumber, missionId, newFile));

      window.sessionStorage.setItem('fileUpload', 'true');
      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }

  const [, setInvoiceNumber] = useState<string>();
  const [, setCompanyName] = useState<string>();
  const [, setLegalStatus] = useState<string>();
  const [, setVatNumber] = useState<string>();
  const [, setDeliveryMainAddress] = useState<string>();
  const [, setDeliveryAdditionalAddress] = useState<string>();
  const [, setDeliveryPostalCode] = useState<string>();
  const [, setDeliveryCity] = useState<string>();
  const [, setDeliveryCountry] = useState<string>();
  const [, setDeliveryCountryText] = useState<string>();
  const [, setBillingMainAddress] = useState<string>();
  const [, setBillingAdditionalAddress] = useState<string>();
  const [, setBillingPostalCode] = useState<string>();
  const [, setBillingCity] = useState<string>();
  const [billingCountry, setBillingCountry] = useState<string>();
  const [, setBillingCountryText] = useState<string>();
  const [, setDirectorFirstName] = useState<string>();
  const [, setDirectorLastName] = useState<string>();
  const [, setDirectorDateOfBirthWithoutFormatting] = useState<string>();
  const [, setDirectorDateOfBirth] = useState<string>();
  const [, setDirectorPlaceOfBirth] = useState<string>();
  const [, setDirectorCountryOfBirth] = useState<string>();
  const [, setDirectorCountryOfBirthText] = useState<string>();
  const [, setDeliveryAddressId] = useState<number>();
  const [, setBillingAddressId] = useState<number>();
  const [, setDirectorId] = useState<number>();
  const [, setContactId] = useState<number>();
  const [, setContactLastName] = useState<string>();
  const [, setContactFirstName] = useState<string>();
  const [, setContactMailAddress] = useState<string>();
  const [, setContactPhoneNumber] = useState<string>();
  const [, setPricing] = useState<PricingParams>(null);

  async function getCompanyDetails() {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getCompanyDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: companyId.companyId }),
        }
      );

      const result = await response.json();

      if (response === null) {
        return;
      } else {
        setCompanyName(result.data.companyName);
        setLegalStatus(result.data.legalStatus);
        setVatNumber(result.data.vatOrRegistrationNumber);
        setDeliveryAddressId(result.data.deliveryAddress);
        setBillingAddressId(result.data.billingAddress);
        setDirectorId(result.data.director);
        setContactId(result.data.contact);

        if (result.data.vatOrRegistrationNumber.slice(0, 2) === 'FR') {
          setVatValue(20);
        }

        setIsLoading(false);

        await getDeliveryAddress(result.data.deliveryAddress);
        await getBillingAddress(result.data.billingAddress);
        await getDirectorDetails(result.data.director);
        await getContactDetais(result.data.contact);

        return JSON.stringify(result.data.vatOrRegistrationNumber);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getDeliveryAddress(addressId: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/address/getById`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: addressId }),
        }
      );

      const result = await response.json();

      if (response === null) {
        return;
      } else {
        setDeliveryMainAddress(result.data.mainAddress);
        setDeliveryAdditionalAddress(result.data.additionalAddress);
        setDeliveryPostalCode(result.data.postalCode);
        setDeliveryCity(result.data.city);
        setDeliveryCountry(result.data.country);

        let deliveryCountry: string;
        if (currentLanguage === 'fr') {
          let country = countriesFR.find(
            (country) => country.code === result.data.country
          );
          deliveryCountry = country ? country.name : 'Country not found';
        } else {
          let country = countriesEN.find(
            (country) => country.code === result.data.country
          );
          deliveryCountry = country ? country.name : 'Country not found';
        }
        setDeliveryCountryText(deliveryCountry);

        setIsLoading(false);

        return JSON.stringify(result.data);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getBillingAddress(addressId: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/address/getById`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: addressId }),
        }
      );

      const result = await response.json();

      if (response === null) {
        return;
      } else {
        setBillingMainAddress(result.data.mainAddress);
        setBillingAdditionalAddress(result.data.additionalAddress);
        setBillingPostalCode(result.data.postalCode);
        setBillingCity(result.data.city);
        setBillingCountry(result.data.country);

        let billingCountry: string;
        if (currentLanguage === 'fr') {
          let country = countriesFR.find(
            (country) => country.code === result.data.country
          );
          billingCountry = country ? country.name : 'Country not found';
        } else {
          let country = countriesEN.find(
            (country) => country.code === result.data.country
          );
          billingCountry = country ? country.name : 'Country not found';
        }
        setBillingCountryText(billingCountry);

        setIsLoading(false);

        return JSON.stringify(result.data);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getDirectorDetails(directorId: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getDirectorDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: directorId }),
        }
      );
      const result = await response.json();

      if (response === null) {
        return;
      } else {
        setDirectorLastName(result.data.lastName);
        setDirectorFirstName(result.data.firstName);
        setDirectorDateOfBirthWithoutFormatting(result.data.dateOfBirth);

        let dateObject = new Date(result.data.dateOfBirth);

        let day = dateObject.getDate();
        let month = dateObject.getMonth() + 1;
        let year = dateObject.getFullYear();

        let formattedDate = `${day.toString().padStart(2, '0')}/${month
          .toString()
          .padStart(2, '0')}/${year}`;

        setDirectorDateOfBirth(formattedDate);
        setDirectorPlaceOfBirth(result.data.placeOfBirth);
        setDirectorCountryOfBirth(result.data.countryOfBirth);

        let directorCountryOfBirth: string;
        if (currentLanguage === 'fr') {
          let country = countriesFR.find(
            (country) => country.code === result.data.countryOfBirth
          );
          directorCountryOfBirth = country ? country.name : 'Country not found';
        } else {
          let country = countriesEN.find(
            (country) => country.code === result.data.countryOfBirth
          );
          directorCountryOfBirth = country ? country.name : 'Country not found';
        }
        setDirectorCountryOfBirthText(directorCountryOfBirth);

        setIsLoading(false);

        return JSON.stringify(result.data);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getContactDetais(contactId: number) {
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getContactDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: contactId }),
        }
      );

      const result = await response.json();

      if (response === null) {
        return;
      } else {
        setContactLastName(result.data.lastName);
        setContactFirstName(result.data.firstName);
        setContactMailAddress(result.data.mailAddress);
        setContactPhoneNumber(result.data.phoneNumber);

        setIsLoading(false);

        return JSON.stringify(result.data);
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async function getPricingPolicy() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/pricingPolicy/getPricingPolicy`,
        {
          method: 'GET',
        }
      );

      const result = await response.json();

      if (response === null) {
        return;
      } else {
        let pricingPolicy: PricingParams = {
          representation: result.data.representationPrice.pricing_amount,
          documentStorage: result.data.documentStoragePrice.pricing_amount,
          management: result.data.managementPrice.pricing_amount,
          sipsiDeclaration: result.data.sipsiDeclarationPrice.pricing_amount,
          constructionCard: result.data.constructionCardPrice.pricing_amount,
          registeredMail1: result.data.registeredMailPrice1.pricing_amount,
          registeredMail2: result.data.registeredMailPrice2.pricing_amount,
          registeredMail3: result.data.registeredMailPrice3.pricing_amount,
          informationDocument:
            result.data.informationDocumentPrice.pricing_amount,
          updateDocument: result.data.updateDocumentPrice.pricing_amount,
          assistance: result.data.assistancePrice.pricing_amount,
        };

        return pricingPolicy;
      }
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  const [, setVatValue] = useState<number>(0);
  const [, setMention] = useState<string>('');

  useEffect(() => {
    let getPricing;
    let getCompanyNumber;
    async function exec() {
      getPricing = await getPricingPolicy();
      getCompanyNumber = await getCompanyDetails();
    }
    exec().then(() => {
      setPricing(getPricing);
      setVatNumber(getCompanyNumber);
      if (window.sessionStorage.getItem('companyInvoiceCountry') === 'FR') {
        setVatValue(20);
        setMention('');
      } else {
        for (var i = 0; i < euCountries.length; i++) {
          if (billingCountry === euCountries[i].countryCode) {
            setMention(
              t('orderPageStrings.commandDetails.reverseChargeMention')
            );
          } else {
            setMention(
              t('orderPageStrings.commandDetails.vatNotApplicableMention')
            );
          }
        }
      }
    });
  }, []);

  async function getCurrentInvoiceValue() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getLastAnnuallyInvoiceId`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: 2,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  function calculateMaxTextWidth(doc: jsPDF, text: string): number {
    let lines: string[] = text.split('\n');
    let maxWidth = 0;

    lines.forEach((line) => {
      let lineWidth = doc.getTextWidth(line);
      if (lineWidth > maxWidth) {
        maxWidth = lineWidth;
      }
    });

    return maxWidth;
  }

  async function generatePDF(
    pricingPolicy: PricingParams,
    invoiceNumber: string
  ): Promise<File> {
    const doc = new jsPDF();

    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    let rCompanyName;
    let rVatNumber;
    let rBillingId;
    let rBillingMain;
    let rBillingAdditional;
    let rBillingPostal;
    let rBillingCity;
    let rBillingCountry;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/getCompanyDetails`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: companyId.companyId }),
        }
      );

      const result = await response.json();

      if (response === null) {
        return;
      } else {
        rCompanyName = result.data.companyName;
        setLegalStatus(result.data.legalStatus);
        rVatNumber = result.data.vatOrRegistrationNumber;
        setDeliveryAddressId(result.data.deliveryAddress);
        rBillingId = result.data.billingAddress;
        setDirectorId(result.data.director);
        setContactId(result.data.contact);

        setIsLoading(false);

        await getDeliveryAddress(result.data.deliveryAddress);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/address/getById`,
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ id: rBillingId }),
            }
          );

          const result = await response.json();

          if (response === null) {
            return;
          } else {
            rBillingMain = result.data.mainAddress;
            rBillingAdditional = result.data.additionalAddress;
            rBillingPostal = result.data.postalCode;
            rBillingCity = result.data.city;
            rBillingCountry = result.data.country;

            let billingCountry: string;
            if (currentLanguage === 'fr') {
              let country = countriesFR.find(
                (country) => country.code === result.data.country
              );
              billingCountry = country ? country.name : 'Country not found';
            } else {
              let country = countriesEN.find(
                (country) => country.code === result.data.country
              );
              billingCountry = country ? country.name : 'Country not found';
            }
            setBillingCountryText(billingCountry);

            setIsLoading(false);

            JSON.stringify(result.data);
          }
        } catch (error) {
          console.log('Erreur', error);
        }
        await getDirectorDetails(result.data.director);
        await getContactDetais(result.data.contact);

        JSON.stringify(result.data.companyId);
      }
    } catch (error) {
      console.log('Erreur', error);
    }

    // Defines invoice details
    const invoiceDetails = {
      date: formatDate(Date.now()),
      items: [],
      total: amount,
    };

    invoiceDetails.items.push({
      description: t('invoiceStrings.representation'),
      quantity: 1,
      amount: Number(pricingPolicy.representation),
    });

    if (withSipsi) {
      invoiceDetails.items.push({
        description: t('invoiceStrings.sipsi'),
        quantity: numberOfEmployees,
        amount: pricingPolicy.sipsiDeclaration * numberOfEmployees,
      });
    }

    if (withConstructionCard && numberOfCards === 0) {
      invoiceDetails.items.push({
        description: t('invoiceStrings.constructionCardModification'),
        quantity: numberOfEmployees,
        amount: pricingPolicy.updateDocument * numberOfEmployees,
      });
    } else if (withConstructionCard) {
      if (numberOfEmployees - numberOfCards === 0) {
        invoiceDetails.items.push({
          description: t('invoiceStrings.constructionCardCreation'),
          quantity: numberOfCards,
          amount: pricingPolicy.constructionCard * numberOfCards,
        });
      } else {
        invoiceDetails.items.push({
          description: t('invoiceStrings.constructionCardModification'),
          quantity: numberOfEmployees - numberOfCards,
          amount:
            pricingPolicy.updateDocument * (numberOfEmployees - numberOfCards),
        });
        invoiceDetails.items.push({
          description: t('invoiceStrings.constructionCardCreation'),
          quantity: numberOfCards,
          amount: pricingPolicy.constructionCard * numberOfCards,
        });
      }
    }

    if (infoDocList.length > 0) {
      invoiceDetails.items.push({
        description: t('invoiceStrings.informationDocument'),
        quantity: infoDocList.length,
        amount: pricingPolicy.informationDocument * infoDocList.length,
      });
    }

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const order = t('globalStrings.invoice.order') + reference;

    // ASD SPW logo
    doc.addImage(spwLogo, 'PNG', 15, 10, 30, 20);

    // Invoice number
    doc.setFontSize(16);
    doc.setFont('Muli-Bold', 'normal');
    doc.text(
      invoiceNumber,
      pageWidth - doc.getTextWidth(invoiceNumber) - 15,
      20
    );

    // MissionReference
    doc.setFontSize(10);
    doc.setFont('Muli-Regular', 'normal');
    doc.text(order, pageWidth - doc.getTextWidth(order) - 15, 25);

    // Issue date
    const issueDate = `${t('globalStrings.invoice.invoiceIssuedOn')}${
      invoiceDetails.date
    }`;
    doc.setFontSize(10);
    doc.text(issueDate, pageWidth - doc.getTextWidth(issueDate) - 15, 35);

    // Seller
    const issueBy = `${t('globalStrings.invoice.invoiceIssuedBy')}`;
    doc.setFont('Muli-Bold', 'normal');
    doc.text(issueBy, 15, 55);
    doc.setFont('Muli-Regular', 'normal');
    doc.text('ASD France', 15, 65);
    doc.text('310 rue du Vallon', 15, 75);
    doc.text('06560 Valbonne', 15, 80);
    doc.text('France', 15, 85);
    doc.text('FR51429463342', 15, 95);

    // Buyer
    const issueFor = `${t('globalStrings.invoice.invoiceIssuedFor')}`;
    doc.setFont('Muli-Bold', 'normal');
    doc.text(issueFor, pageWidth / 2, 55);
    doc.setFont('Muli-Regular', 'normal');
    doc.text(rCompanyName, pageWidth / 2, 65);
    doc.text(rBillingMain, pageWidth / 2, 75);
    doc.text(rBillingPostal + ' ' + rBillingCity, pageWidth / 2, 80);
    doc.text(rBillingCountry, pageWidth / 2, 85);
    doc.text(rVatNumber, pageWidth / 2, 95);
    doc.text(t('invoiceStrings.serviceProvision'), 15, 105);

    let sum = 0;
    invoiceDetails.items.forEach((item) => {
      sum += Number(item.amount);
    });

    invoiceDetails.items.push({
      description: `${t('invoiceStrings.periodAndEmployees')}\n(${formattedDate(
        startDate
      )} - ${formattedDate(endDate)} - ${numberOfEmployees} ${t(
        'invoiceStrings.employees'
      )})`,
      quantity: '',
      amount: Number((amount - sum).toFixed(2)),
    });

    // Order details
    (doc as any).autoTable({
      startY: 110,
      head: [
        [
          t('globalStrings.invoice.description'),
          t('globalStrings.invoice.pricePerUnit'),
          t('globalStrings.invoice.quantity'),
          t('globalStrings.invoice.price'),
        ],
      ],
      body: invoiceDetails.items.map((item) => [
        item.description,
        item.quantity !== '' ? item.amount / item.quantity + ' €' : '',
        item.quantity,
        item.amount + ' €',
      ]),
      styles: {
        font: 'Muli-Regular',
        fontSize: 10,
      },
      headStyles: {
        fillColor: '#ed6723',
        font: 'Muli-Bold',
        fontSize: 12,
      },
      columnStyles: {
        0: { halign: 'left' },
        1: { halign: 'right' },
        2: { halign: 'right' },
        3: { halign: 'right' },
      },
      didParseCell: function (data) {
        if (data.section === 'head') {
          if (data.column.index === 0) {
            data.cell.styles.halign = 'left';
          } else if (
            data.column.index === 1 ||
            data.column.index === 2 ||
            data.column.index === 3
          ) {
            data.cell.styles.halign = 'right';
          }
        }
      },
    });

    // Bank details
    const bankDetails = `${t('globalStrings.invoice.bankDetails')}`;
    doc.setFont('Muli-Bold', 'normal');
    doc.text(bankDetails, 15, (doc as any).lastAutoTable.finalY + 20);
    doc.setFont('Muli-Regular', 'normal');
    doc.text(
      'CIC ALPES-MARITIMES ENTREPRISES',
      15,
      (doc as any).lastAutoTable.finalY + 30
    );
    doc.text('3 rue de la Liberté', 15, (doc as any).lastAutoTable.finalY + 40);
    doc.text('06000 Nice', 15, (doc as any).lastAutoTable.finalY + 45);
    doc.text('France', 15, (doc as any).lastAutoTable.finalY + 50);
    doc.text(
      `${t('globalStrings.invoice.iban')}FR7610096185830005329940189`,
      15,
      (doc as any).lastAutoTable.finalY + 60
    );
    doc.text(
      `${t('globalStrings.invoice.bic')}CMCIFRPP`,
      15,
      (doc as any).lastAutoTable.finalY + 65
    );

    let companyCountry: string = window.sessionStorage.getItem(
      'companyInvoiceCountry'
    );

    let myVatValue = 0;
    let myMention = '';
    console.log(companyCountry);
    if (companyCountry === 'FR') {
      myVatValue = 20;
    } else {
      for (var i = 0; i < euCountries.length; i++) {
        if (companyCountry === euCountries[i].countryCode) {
          myMention = t('orderPageStrings.commandDetails.reverseChargeMention');
          break;
        } else {
          myMention = t(
            'orderPageStrings.commandDetails.vatNotApplicableMention'
          );
        }
      }
    }

    // Order amount
    const totalExludingVat = `${t(
      'orderPageStrings.commandDetails.totalExclVat'
    )} ${invoiceDetails.total} €`;
    const vatAmount = `${t(
      'orderPageStrings.commandDetails.vatAmount'
    )} (${myVatValue}%)${t('globalStrings.invoice.punctuation')} ${(
      (Number(amount) * myVatValue) /
      100
    ).toFixed(2)} €`;
    const totalIncludingVat = `${t(
      'orderPageStrings.commandDetails.totalInclVat'
    )} ${(Number(amount) + (Number(amount) * myVatValue) / 100).toFixed(2)} €`;

    doc.setFontSize(6);
    doc.setTextColor('#808080');
    doc.text(myMention, 15, (doc as any).lastAutoTable.finalY + 5);
    doc.setFontSize(10);
    doc.setTextColor('#000000');
    doc.text(
      totalExludingVat,
      pageWidth - doc.getTextWidth(totalExludingVat) - 15,
      (doc as any).lastAutoTable.finalY + 20
    );
    doc.text(
      vatAmount,
      pageWidth - doc.getTextWidth(vatAmount) - 15,
      (doc as any).lastAutoTable.finalY + 25
    );
    doc.setFont('Muli-Bold', 'normal');
    doc.setFontSize(12);
    doc.text(
      totalIncludingVat,
      pageWidth - doc.getTextWidth(totalIncludingVat) - 15,
      (doc as any).lastAutoTable.finalY + 35
    );

    // Footer
    const footerText =
      'ASD France - 310 rue du Vallon – BP 203 – 06904 Sophia Antipolis Cedex\nwww.asd-int.com | www.myasd.com | 04.92.38.08.05\nRCS Grasse - SIRET B 429 463 342 00049 - APE 8299Z – SARL au capital de 800 000€';
    const footerLogoWidth = 25;
    const footerLogoHeight = 25;
    const footerWidth = calculateMaxTextWidth(doc, footerText);
    const footerCentered = (pageWidth - footerWidth) / 2;

    doc.setFontSize(8);
    doc.setFont('Muli-Regular', 'normal');
    doc.line(10, pageHeight - 20, pageWidth - 10, pageHeight - 20);

    doc.addImage(
      groupLogo,
      'PNG',
      footerCentered + footerLogoWidth / 1.5,
      pageHeight - 22,
      footerLogoWidth,
      footerLogoHeight
    );
    doc.setTextColor(0, 0, 0);
    doc.text(
      footerText,
      footerCentered + 1.75 * footerLogoWidth,
      pageHeight - 12
    );

    // Save file
    setInvoiceNumber(invoiceNumber);
    const pdfData = doc.output('blob');
    return new File([pdfData], invoiceNumber + '.pdf', {
      type: 'application/pdf',
    });

    // doc.save(invoiceNumber + '.pdf');
  }

  const formattedDate = (dataString: string): string => {
    let dateObject = new Date(dataString);

    let day = dateObject.getDate();
    let month = dateObject.getMonth() + 1;
    let year = dateObject.getFullYear();

    let formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;

    return formattedDate;
  };

  async function createInvoice(
    invoiceNumber: string,
    missionId: number,
    fileName: string
  ) {
    const values = JSON.stringify({
      reference: invoiceNumber,
      date: new Date(),
      missionId: missionId,
      billerId: 1,
      companyId: companyId.companyId,
      s3Path: `invoice_mission_${missionId}/${invoiceNumber}/${fileName}`,
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/newInvoice`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: values,
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  useEffect(() => {
    async function setupPaymentForm() {
      const endpoint = 'https://api.payzen.eu';
      const publicKey = `${process.env.REACT_APP_PAYZEN_PUBLIC_KEY}`;
      let formToken;
      let companyCountry: string = window.sessionStorage.getItem(
        'companyInvoiceCountry'
      );

      let myVatValue = 0;
      console.log(companyCountry);
      if (companyCountry === 'FR') {
        myVatValue = 20;
      }

      console.log(myVatValue);

      const updateAmount = (
        Number(window.sessionStorage.getItem('totalPrice')) +
        (Number(window.sessionStorage.getItem('totalPrice')) * myVatValue) / 100
      ).toFixed(2);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/createPayment`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              paymentConf: {
                amount: updateAmount.includes('.')
                  ? updateAmount.length - updateAmount.lastIndexOf('.') - 1 < 2
                    ? Number((amount + '0').replace('.', ''))
                    : Number(updateAmount.replace('.', ''))
                  : Number(amount + '00'),
                currency: 'EUR',
              },
            }),
          }
        );
        formToken = await response.text();

        const { KR } = await KRGlue.loadLibrary(endpoint, publicKey);

        await KR.setFormConfig({
          formToken: formToken,
          'kr-language': currentLanguage === 'fr' ? 'fr-FR' : 'en-UK',
        }).then(() => setIsLoading(false));

        await KR.onSubmit(async (paymentData) => {
          const response = await fetch(
            `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/validatePayment`,
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(paymentData),
            }
          );
          if (response.status === 200) {
            setMessage(t('payzenStrings.payementSuccessful'));
            setTimeout(() => {
              setIsLoading(true);
              createMissionReference().then(() => createNewMission(reference));
            }, 2000);
          }
          return false; // Return false to prevent the redirection
        });

        await KR.renderElements('#myPaymentForm');
      } catch (error) {
        setMessage(error + ' (see console for more details)');
      }
    }

    setupPaymentForm();
  }, []);

  return (
    <>
      {(isLoading && <Loader />) ||
        (!isLoading && (
          <div className='form'>
            <div className='container'>
              <div id='myPaymentForm'>
                <div className='kr-smart-form' kr-card-form-expanded></div>
              </div>
              <div data-test='payment-message'>{message}</div>
            </div>
          </div>
        ))}
    </>
  );
};

export default PaymentPage;
