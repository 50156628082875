/**
 * Dependencies.
 */
import React, { Component, createRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import tippy from 'tippy.js';

/**
 * Resources.
 */
import './endCustomerForm.css';
import 'tippy.js/dist/tippy.css';
import tooltip from '../../assets/images/logos/tooltip.png';
import TextInput from '../textInput/textInput';
import AddressBlock from '../addressBlock/addressBlock';

/**
 * EndCustomerFormProps interface defines the structure of props
 * expected by the EndCustomerForm component.
 */
interface EndCustomerFormProps extends WithTranslation {}

/**
 * EndCustomerForm component displays a part of OrderForm,
 * that gives user possibility to precise the company that
 * needs posting workers.
 */
class EndCustomerForm extends Component<EndCustomerFormProps> {
  private endCustomerTooltipRef;

  constructor(props) {
    super(props);

    this.endCustomerTooltipRef = createRef();
  }

  componentDidMount(): void {
    if (this.endCustomerTooltipRef.current) {
      tippy(this.endCustomerTooltipRef.current, {
        content: this.props.t(
          'missionDetailsPageStrings.tooltips.endCustomerTooltip'
        ),
        placement: 'right',
        arrow: true,
      });
    }
  }

  render() {
    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    let companyName: string = window.sessionStorage.getItem(
      'endCustomerCompanyName'
    );

    let mainAddress: string = window.sessionStorage.getItem(
      'endCustomerMainAddress'
    );

    let additionalAddress: string = window.sessionStorage.getItem(
      'endCustomerAdditionalAddress'
    );

    let postalCode: string = window.sessionStorage.getItem(
      'endCustomerPostalCode'
    );

    let city: string = window.sessionStorage.getItem('endCustomerCity');

    let country: string = window.sessionStorage.getItem('endCustomerCountry');

    let mailAddress: string = window.sessionStorage.getItem(
      'endCustomerMailAddress'
    );

    let vatNumber: string = window.sessionStorage.getItem(
      'endCustomerVatNumber'
    );

    let siren: string = window.sessionStorage.getItem('endCustomerSiren');

    function handleCompanyNameChange(value: string) {
      window.sessionStorage.setItem('endCustomerCompanyName', value);
    }

    function handleMainAddressChange(value: string) {
      window.sessionStorage.setItem('endCustomerMainAddress', value);
    }

    function handleAdditionalAddressChange(value: string) {
      window.sessionStorage.setItem('endCustomerAdditionalAddress', value);
    }

    function handlePostalCodeChange(value: string) {
      window.sessionStorage.setItem('endCustomerPostalCode', value);
    }

    function handleCityChange(value: string) {
      window.sessionStorage.setItem('endCustomerCity', value);
    }

    function handleCountryChange(value: string) {
      window.sessionStorage.setItem('endCustomerCountry', value);
    }

    function handleMailAddressChange(value: string) {
      window.sessionStorage.setItem('endCustomerMailAddress', value);
    }

    function handleVatNumberChange(value: string) {
      window.sessionStorage.setItem('endCustomerVatNumber', value);
    }

    function handleSirenChange(value: string) {
      window.sessionStorage.setItem('endCustomerSiren', value);
    }

    return (
      <div className='endCustomerFormContainer'>
        <div className='endCustomerFormHeader'>
          <i>{t('orderPageStrings.endCustomer.details')}</i>
          <img
            src={tooltip}
            alt='tooltipButton'
            className='endCustomerFormTooltip'
            ref={this.endCustomerTooltipRef}
          />
        </div>
        <div className='endCustomerFormSubBlock'>
          <TextInput
            classname='endCustomreFormTextInput'
            placeholder={t(
              'orderPageStrings.endCustomer.placeholders.companyName'
            )}
            initialValue={companyName}
            onSave={handleCompanyNameChange}
          />
        </div>
        <div className='endCustomerFormSubBlock'>
          <AddressBlock
            divClassName='endCustomerFormAddressBlock'
            initialMainAddress={mainAddress}
            initialAdditionalAddress={additionalAddress}
            initialPostalCode={postalCode}
            initialCity={city}
            initialCountry={country}
            onSaveMainAddress={handleMainAddressChange}
            onSaveAdditionalAddress={handleAdditionalAddressChange}
            onSavePostalCode={handlePostalCodeChange}
            onSaveCity={handleCityChange}
            onSaveCountry={handleCountryChange}
          />
        </div>
        <div className='endCustomerFormSubBlock'>
          <TextInput
            classname='endCustomreFormTextInput'
            placeholder={t(
              'orderPageStrings.endCustomer.placeholders.mailAddress'
            )}
            initialValue={mailAddress}
            onSave={handleMailAddressChange}
          />
        </div>
        <div className='endCustomerFormSubBlock'>
          <TextInput
            classname='endCustomreFormTextInput'
            placeholder={t(
              'orderPageStrings.endCustomer.placeholders.vatNumber'
            )}
            initialValue={vatNumber}
            onSave={handleVatNumberChange}
          />
        </div>
        <div className='endCustomerFormSubBlock'>
          <TextInput
            classname='endCustomreFormTextInput'
            placeholder={t('orderPageStrings.endCustomer.placeholders.siren')}
            initialValue={siren}
            onSave={handleSirenChange}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(EndCustomerForm);
