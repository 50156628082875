/**
 * Checks whether an array of string variables is correctly filled in.
 *
 * @param strings An array of strings whose contents must be verified.
 *
 * @returns A boolean who confirms or not the array validating state.
 */
export function areAllStringsValid(strings: (string | undefined)[]): boolean {
  return strings.every((str) => str !== undefined && str.trim() !== '');
}
