import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions } from 'store';
import { Formiz, useForm } from '@formiz/core';
import { isEmail } from '@formiz/validations';

import ImageCustom from 'components/core/ImageCustom/ImageCustom';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Spacer from 'components/core/Spacer/Spacer';
import FormizSimpleInput from 'components/FormizField/FormizSimpleInput/FormizSimpleInput';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import authPaths from 'routes/auth/paths';
import images from 'assets/images';
import ImageLogin from 'components/core/ImageLogin/ImageLogin';

const Index = ({ dispatch, history, intl }) => {
  const forgottenPasswordForm = useForm();

  const send = (values) => {
    dispatch(actions.user.resetPassword({ data: values }));
    history.push(authPaths.login);
  };

  return (
    <Wrapper
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      margin='30px'
    >
      <ImageLogin
        alt='Logo ASD'
        margin='50px'
        height='190px'
        maxHeight='190px'
        maxWidth='20em'
      />
      <Wrapper textAlign='center' margin='0 30px 0 30px'>
        <p>
          You will receive an email with a link where you can choose a new
          password.
        </p>
      </Wrapper>
      <Spacer height='10px' />
      <Formiz connect={forgottenPasswordForm} onValidSubmit={send}>
        <form onSubmit={forgottenPasswordForm.submit} noValidate>
          <FormizSimpleInput
            type='email'
            name='email'
            label='Email'
            placeholder='test@test.com'
            required='Email is required'
            validations={[
              {
                rule: isEmail(),
                message: 'This is not a valid email',
              },
            ]}
          />
          <Spacer height='10px' />
          <Button
            type='submit'
            content={intl.formatMessage({
              id: 'button.send',
            })}
          />
        </form>
      </Formiz>
      <Spacer height='10px' />
      <ButtonBack history={history} />
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
