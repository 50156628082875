/**
 * Dependencies.
 */
import React, { Component, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

/**
 * Resources.
 */
import './endCustomerMissionDetails.css';
import { areAllStringsValid } from '../../utils/areAllStringsValid';
import saveLogo from '../../assets/images/logos/saveWhite.png';
import Loader from '../../../../components/Loader';
import TextInput from '../textInput/textInput';
import AddressBlock from '../addressBlock/addressBlock';

/**
 * EndCustomerMissionDetailsProps interface defines the structure of props
 * expected by the EndCustomerMissionDetails component.
 */
interface EndCustomerMissionDetailsProps extends WithTranslation {
  endCustomerId: number;
  endCustomerAddressId;
  initialCompanyName: string;
  initialMainAddress: string;
  initialAdditionalAddress: string;
  initialPostalCode: string;
  initialCity: string;
  initialCountry: string;
  initialMailAddress: string;
  initialVatNumber: string;
  initialSiren: string;
}

/**
 * EndCustomerMissionDetailsState defines all values
 * that can be constantly update by user as long as
 * he's on component parent page.
 */
interface EndCustomerMissionDetailsState {
  isLoading: boolean;
  companyName: string;
  mainAddress: string;
  additionalAddress: string;
  postalCode: string;
  city: string;
  country: string;
  mailAddress: string;
  vatNumber: string;
  siren: string;
  isErrorHidden: boolean;
}

/**
 * EndCustomerMissionDetails component displays a form that gives
 * possibility to fill in all information about an end customer.
 */
class EndCustomerMissionDetails extends Component<
  EndCustomerMissionDetailsProps,
  EndCustomerMissionDetailsState
> {
  constructor(props: EndCustomerMissionDetailsProps) {
    super(props);
    this.state = {
      isLoading: false,
      companyName: props.initialCompanyName,
      mainAddress: props.initialMainAddress,
      additionalAddress: props.initialAdditionalAddress,
      postalCode: props.initialPostalCode,
      city: props.initialCity,
      country: props.initialCountry,
      mailAddress: props.initialMailAddress,
      vatNumber: props.initialVatNumber,
      siren: props.initialSiren,
      isErrorHidden: true,
    };

    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.handleMainAddressChange = this.handleMainAddressChange.bind(this);
    this.handleAdditionalAddressChange =
      this.handleAdditionalAddressChange.bind(this);
    this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleMailAddressChange = this.handleMailAddressChange.bind(this);
    this.handleVatNumberChange = this.handleVatNumberChange.bind(this);
    this.handleSirenChange = this.handleSirenChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
  }

  handleCompanyNameChange(value: string) {
    this.setState({ companyName: value });
  }

  handleMainAddressChange(value: string) {
    this.setState({ mainAddress: value });
  }

  handleAdditionalAddressChange(value: string) {
    this.setState({ additionalAddress: value });
  }

  handlePostalCodeChange(value: string) {
    this.setState({ postalCode: value });
  }

  handleCityChange(value: string) {
    this.setState({ city: value });
  }

  handleCountryChange(value: string) {
    this.setState({ country: value });
  }

  handleMailAddressChange(value: string) {
    this.setState({ mailAddress: value });
  }

  handleVatNumberChange(value: string) {
    this.setState({ vatNumber: value });
  }

  handleSirenChange(value: string) {
    this.setState({ siren: value });
  }

  async handleClickSave() {
    let stringsToCheck = [
      this.state.companyName,
      this.state.mainAddress,
      this.state.postalCode,
      this.state.city,
      this.state.country,
      this.state.mailAddress,
      this.state.vatNumber,
      this.state.siren,
    ];

    if (areAllStringsValid(stringsToCheck)) {
      this.setState({ isErrorHidden: true });
      await this.updateAddress();
      await this.updateEndCustomer();
      this.setState({ isLoading: false });
      window.location.reload();
    } else {
      this.setState({ isErrorHidden: false });
    }
  }

  async updateAddress() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/address/updateById`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.props.endCustomerAddressId,
            mainAddress: this.state.mainAddress,
            additionalAddress: this.state.additionalAddress,
            postalCode: this.state.postalCode,
            city: this.state.city,
            country: this.state.country,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  async updateEndCustomer() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEV_API_URL}/api/v1/spw/endCustomer/updateById`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.props.endCustomerId,
            companyName: this.state.companyName,
            mailAddress: this.state.mailAddress,
            vatNumber: this.state.vatNumber,
            siren: this.state.siren,
          }),
        }
      );

      const result = await response.json();

      return result.data;
    } catch (error) {
      console.log('Erreur', error);
    }
  }

  componentDidMount(): void {
    this.forceUpdate();
  }

  render(): ReactNode {
    const {
      isLoading,
      companyName,
      mainAddress,
      additionalAddress,
      postalCode,
      city,
      country,
      mailAddress,
      vatNumber,
      siren,
      isErrorHidden,
    } = this.state;

    /**
     * Extract the `t` function from `useTranslation` hook.
     */
    const { t } = this.props;

    return (
      <>
        {isLoading && <Loader />}
        <div className='endCustomerMissionDetailsContainer'>
          <div className='endCustomerMissionDetailsUpdatableFields'>
            <p>
              <b>{t('endCustomerUpdateStrings.companyNameTitle')}</b>
            </p>
            <TextInput
              classname='endCustomerMissionDetailsUpdateCompanyName'
              placeholder={t('endCustomerUpdateStrings.companyNamePlaceholder')}
              initialValue={companyName}
              onSave={this.handleCompanyNameChange}
            />
            <AddressBlock
              divClassName='endCustomerMissionDetailsUpdateAddress'
              title={t('endCustomerUpdateStrings.addressTitle')}
              initialMainAddress={mainAddress}
              initialAdditionalAddress={additionalAddress}
              initialPostalCode={postalCode}
              initialCity={city}
              initialCountry={country}
              onSaveMainAddress={this.handleMainAddressChange}
              onSaveAdditionalAddress={this.handleAdditionalAddressChange}
              onSavePostalCode={this.handlePostalCodeChange}
              onSaveCity={this.handleCityChange}
              onSaveCountry={this.handleCountryChange}
            />
            <p>
              <b>{t('endCustomerUpdateStrings.mailAddressTitle')}</b>
            </p>
            <TextInput
              classname='endCustomerMissionDetailsUpdateMailAddress'
              placeholder={t('endCustomerUpdateStrings.mailAddressPlaceholder')}
              initialValue={mailAddress}
              onSave={this.handleMailAddressChange}
            />
            <p>
              <b>{t('endCustomerUpdateStrings.vatNumberTitle')}</b>
            </p>
            <TextInput
              classname='endCustomerMissionDetailsUpdateVatNumber'
              placeholder={t('endCustomerUpdateStrings.vatNumberPlaceholder')}
              initialValue={vatNumber}
              onSave={this.handleVatNumberChange}
            />
            <p>
              <b>{t('endCustomerUpdateStrings.sirenTitle')}</b>
            </p>
            <TextInput
              classname='endCustomerMissionDetailsUpdateSiren'
              placeholder={t('endCustomerUpdateStrings.sirenPlaceholder')}
              initialValue={siren}
              onSave={this.handleSirenChange}
            />
          </div>
          <div className='endCustomerMissionDetailsFooter'>
            {isErrorHidden ? (
              <></>
            ) : (
              <div className='endCustomerMissionDetailsFieldsError'>
                <p>{t('orderPageStrings.error')}</p>
              </div>
            )}
            <div className='endCustomerMissionDetailsSaveButtonContainer'>
              <button
                className='endCustomerMissionDetailsSaveButton'
                onClick={this.handleClickSave}
              >
                <p>{t('popupStrings.saveText')}</p>
                <img
                  src={saveLogo}
                  alt={t('popupStrings.saveLogo')}
                  className='endCustomerMissionDetailsSaveLogo'
                />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(EndCustomerMissionDetails);
